<template>
    <div class="animated fadeIn">
        <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
        <!-- v-else -->
        <b-card v-else>
            <template v-slot:header>
                <h5>Jenis Barang {{ formEdit.activeGolongan }}</h5>
            </template>
            <div class="search-wrapper d-flex align-items-center justify-content-between">
                <b-form-group label="Filter" label-for="filterInput" class="form-inline form-group-wrapper mb-3">
                    <div class="d-flex search-input-wrapper">
                        <b-form-input class="ml-2 mr-2" v-model="filter.jenis" name="filterTable" id="filterInput" @keyup="getKategori()"
                            type="search" placeholder="Cari Jenis"></b-form-input>
                        <b-form-select class="ml-2 mr-2" id="filterInput" v-model="filter.golongan" name="filterJenis" @change="getKategori" >
                            <template v-slot:first>
                                <b-form-select-option :value="null" disabled>-- Pilih Golongan --</b-form-select-option>
                            </template>
                            <b-form-select-option v-for="g in golongan" :value="g.id"   >
                                {{ g.nama }}
                            </b-form-select-option>
                        </b-form-select>
                        <b-button variant="pgiBtn" name="resetTableBtn" :disabled="isBusy"
                            @click="onReset()">Reset</b-button>
                    </div>
                </b-form-group>
                <div>
                    <b-button class="mb-3 mr-2" variant="pgiBtn" name="kategoriAddBtn"
                        @click="$bvModal.show('m-create')">Input Jenis Barang</b-button>
                </div>
            </div>
            <b-table bordered hover show-empty :busy="isBusy" :items="kategori" :fields="fields" :perPage="perPage"
                responsive class="mb-3">
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Loading data...</strong>
                    </div>
                </template>
                <template v-slot:empty>
                    Tidak ada data jenis barang.
                </template>
                <template v-slot:cell(kelengkapan)="row">
                    <ul class="list-none-wrapper" v-if="row.item.kelengkapan.length > 0">
                        <li v-for="kelengkapan in row.item.kelengkapan" v-bind:key="kelengkapan.id">
                            <span>
                                <i class="fa fa-check" v-if="(kelengkapan.isActive == true)"></i>
                                <i class="fa fa-times" v-if="(kelengkapan.isActive == false)"></i>
                                {{ kelengkapan.name }}
                            </span>
                        </li>
                    </ul>
                    <span v-else>-</span>
                </template>
                <template v-slot:cell(action)="row">
                    <div class="no-wrap">
                        <b-button size="sm" class="mr-3 table-button edit" v-b-tooltip.hover title="Edit"
                            name="jenisBarangEditBtn"
                            @click="showModalEdit(`${row.item.id}`, `${row.item.jenisBarang}`, `${row.item.idKategori}` , `${row.item.kategori}`)">
                            <i class="fa fa-pencil-square-o"></i>
                        </b-button>
                        <b-button size="sm" class="mr-3 table-button edit" v-b-tooltip.hover title="Delete"
                            name="jenisBarangHapusBtn" @click="showModalHapus(`${row.item.id}`)">
                            <i class="fa fa-trash text-danger"></i>
                        </b-button>
                    </div>
                </template>
            </b-table>

            <div class="pagination-wrapper d-flex align-items-center justify-content-between">
                <span class="ml-2">{{ totalViewText }}</span>
                <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="my-table" @input="onPageChange"></b-pagination>
            </div>
        </b-card>


        <!-- MODAL CREATE -->
        <b-modal id="m-create" class="modal-lg" title="Input Jenis Barang" no-close-on-backdrop no-close-on-esc hide-footer>

            <b-form-group id="input-jenis" label="Nama Jenis" label-for="input-jenis">
                <b-form-input id="input-jenis" v-model="form.jenis" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-3" label="Golongan" label-for="golongan">
                <b-form-select id="golongan" v-model="form.id_kategori" required>
                    <template v-slot:first>
                        <b-form-select-option :value="null" disabled>-- Pilih Golongan --</b-form-select-option>
                    </template>
                    <b-form-select-option v-for="g in golongan" :value="g.id">
                        {{ g.nama }}
                    </b-form-select-option>
                </b-form-select>
            </b-form-group>

            <div class="mt-4 text-right">
                <b-button variant="pgiBtn" @click="prepare('m-confirm-create')">
                    Simpan
                </b-button>
            </div>
        </b-modal>

        <ModalConfirm id="m-confirm-create" title="Confirm Tambah Jenis Barang" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk menambah Jenis Barang ini ?" @submit="submitInput" />

        <!-- MODAL EDIT -->
        <b-modal id="m-edit" class="modal-lg" title="Edit Jenis Barang" no-close-on-backdrop no-close-on-esc hide-footer>

            <b-form-group id="edit-jenis" label="Nama Jenis" label-for="edit-jenis">
                <b-form-input id="edit-jenis" v-model="formEdit.activeJenis" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-3" label="Golongan" label-for="golongan">
                <b-form-select id="golongan" v-model="formEdit.activeIdGolongan" required>
                    <template v-slot:first>
                        <b-form-select-option :value="null" disabled>-- Pilih Golongan --</b-form-select-option>
                    </template>
                    <b-form-select-option v-for="g in golongan" :value="g.id">
                        {{ g.nama }}
                    </b-form-select-option>
                </b-form-select>
            </b-form-group>

            <div class="mt-4 text-right">
                <b-button variant="pgiBtn" @click="prepare('m-confirm-edit')">
                    Simpan
                </b-button>
            </div>
        </b-modal>

        <ModalConfirm id="m-confirm-edit" title="Confirm Ubah jenis barang" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk mengubah Jenis Barang ini ?" @submit="submitEdit" />

        <ModalConfirm id="m-confirm-hapus" title="Confirm Hapus jenis barang" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk menghapus jenis barang ini ?" @submit="hapus" />

    </div>
</template>

<script>

export default {
    name: 'master-jenis-barang',
    data() {
        return {
            initStatus: 0,
            isBusy: true,
            isSubmitting: false,
            input: {
                show: 10
            },

            filter: {
                jenis: null,
                golongan: null,
            },

            form: {
                jenis: '',
                id_kategori: null,
                golongan: null,
            },

            activeId: null,

            formEdit: {
                activeJenis: '',
                activeIdGolongan: null,
                activeGolongan: null,
            },

            golongan: [],

            fields: [
                { key: "jenisBarang", label: "Jenis Barang"},
                { key: "kategori", label: "Kategori"},
                { key: "action", label: "Aksi" },
            ],
            options: [
                { value: 10, text: '10' },
                { value: 20, text: '20' },
                { value: 30, text: '30' },
                { value: 40, text: '40' },
                { value: 50, text: '50' },
            ],
            kategoriAll: [],
            kategori: [],
            currentPage: 1,
            perPage: null,
            rows: null,
            totalViewText: "",
            page: '',
            size: '',
            from: '',
            limit: '',
            loading: false,
            query: ""
        }
    },
    methods: {

        //----------------- Fungsi Inisialisasi -----------------

        init() {
            console.log('ke init');
            this.initStatus = 0
            this.page = 1
            this.query = "?page=" + this.page

            let getGolongan = this.$axios.get("api/admin/kategori").then(response => {
                //console.log(response.data);
                let datas = response.data.data.data
                console.log(datas);

                for(var data of datas){
                    this.golongan.push({
                        id : data.id,
                        nama : data.nama
                    })
                }

                console.log(this.golongan);

            })


            let getKategori = this.$axios.get("api/admin/barang-jenis").then(response => {
                //console.log(response.data);
                let datas = response.data.data

                console.log(datas);

                for (var data of datas.data) {
                    this.kategori.push({ id: data.id, jenisBarang: data.jenis, idKategori: data.id_kategori ,kategori: data.golongan })
                }

                console.log(this.kategori);
                this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to

                console.log(this.perPage);
                console.log(this.size);
                console.log(this.from);
                console.log(this.limit);
                this.updateTotalItem(datas.total)
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)
            })

            Promise.all([getGolongan,getKategori]).then(() => {
                this.initStatus = 1
                this.toggleBusy()
            })
                .catch(error => {
                    console.log(error)
                    this.initStatus = -1
                    this.toggleBusy()
                })
        },
        
        getKategori() {

            this.toggleBusy()
            this.kategori = []

            if (this.filter.jenis != null) this.query = this.query + "&jenis=" + this.filter.jenis
            if (this.filter.golongan != null) this.query = this.query + "&id_kategori=" + this.filter.golongan

            console.log(this.query);

            this.$axios.get("api/admin/barang-jenis" + this.query).then(response => {
                
                let datas = response.data.data
                console.log(datas);
                //this.updateTotalItem(datas.total)
                for (var data of datas.data) {
                    this.kategori.push({ id: data.id, jenisBarang: data.jenis, idKategori: data.id_kategori ,kategori: data.golongan })
                }

                this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to
               
                this.toggleBusy()
                this.updateTotalItem(datas.total)
                this.loading = false

            })
                .catch(error => {
                    console.log(error)
                    console.log(error.response.data.reason)
                    this.$helper.parseError(this, error)
                    this.toggleBusy()
                    this.loading = false
                })
        },

        //-----------------  Fungsi Input ----------------- 
        prepare(modalToShow) {

            console.log(modalToShow);
            console.log(this.formEdit.activeGolongan);
            //EDIT
            if (modalToShow == "m-confirm-edit") {
                if (this.formEdit.activeJenis == "") {
                    return this.$helper.toastErr(this, "Mohon mengisi Barang Jenis!")

                }

                if (this.formEdit.activeGolongan == null) {
                    return this.$helper.toastErr(this, "Mohon mengisi Golongan!")
                }
            }

            //CREATE
            else {

                if (this.form.jenis == "") {
                    return this.$helper.toastErr(this, "Mohon mengisi Barang Jenis!")
                }

                console.log(this.form.id_kategori);

                if (this.form.id_kategori == null) {
                    return this.$helper.toastErr(this, "Mohon mengisi Golongan!")
                }

                let idx = this.golongan.findIndex((item) => item.id == this.form.id_kategori )
                this.form.golongan = this.golongan[idx].nama

            }

            this.$bvModal.show(modalToShow)

        },

        submitInput() {

            console.log(this.form.golongan)
            this.isSubmitting = true
            this.$axios.post("/api/admin/barang-jenis", this.form).then(res => {

                this.$helper.toastSucc(this, "Kategori Berhasil Ditambahkan")
                this.$bvModal.hide("m-confirm-create")
                this.$bvModal.hide("m-create")

                this.form = {}
                this.formEdit = {}
                this.getKategori()

            })
                .catch(error => this.$helper.parseError(this, error))
                .finally(() => this.isSubmitting = false)

        },

        //-----------------  Fungsi Edit ----------------- 
        showModalEdit(id, jenisBarang, idKategori,kategori) {

            this.activeId = id
            this.formEdit.activeJenis = jenisBarang
            this.formEdit.activeIdGolongan = idKategori
            this.formEdit.activeGolongan = kategori

            console.log(this.activeId)
            console.log(this.formEdit.activeJenis)
            console.log(this.formEdit.activeIdGolongan)
            console.log(this.formEdit.activeGolongan)

            this.$bvModal.show('m-edit')
        },

        submitEdit() {
            let idx = this.golongan.findIndex((item) => item.id == this.formEdit.activeIdGolongan )
            let formEdit = {
                jenis: this.formEdit.activeJenis,
                id_kategori: this.formEdit.activeIdGolongan,
                golongan: this.golongan[idx].nama
            }

            console.log(formEdit);

            this.isSubmitting = true
            this.$axios.post("/api/admin/barang-jenis/" + this.activeId, formEdit).then(res => {

                this.$helper.toastSucc(this, "Kategori Berhasil Diedit")
                this.$bvModal.hide("m-confirm-edit")
                this.$bvModal.hide("m-edit")

                this.form = {}
                this.getKategori()

            })
                .catch(error => this.$helper.parseError(this, error))
                .finally(() => this.isSubmitting = false)

        },

        //-----------------  Fungsi Hapus ----------------- 
        showModalHapus(id) {
            this.activeId = id
            this.$bvModal.show('m-confirm-hapus')
        },

        hapus() {
            this.$axios.delete("api/admin/barang-jenis/delete/" + this.activeId).then(response => {

                this.$helper.toastSucc(this, "Jenis Barang Berhasil Dihapus")
                this.$bvModal.hide("m-confirm-hapus")

                this.form = {}
                this.formEdit = {}
                this.getKategori()
            })
        },

        //----------------- Fungsi Pagination, Sorting, dan Filtering -----------------

        // onFilter() {
        //     this.page = 1
        //     this.currentPage = 1
        //     this.query = "?page=" + this.page
        //     this.loading = true
        //     this.getKategori()
        // },

        onPageChange() {
            console.log("ke on page");
            if (this.loading == false) {
                console.log(this.currentPage);
                this.page = this.currentPage
                this.query = "?page=" + this.page

                this.getKategori()
            }
            console.log(this.query);
        },

        onReset() {
            this.filter = {
                jenis: null,
                golongan: null,
            },

            this.page = 1
            this.query = "?page=" + this.page
            this.currentPage = 1
            this.loading = true
            this.getKategori()
        },

        //----------------- Fungsi Pindah Halaman dan Fungsi Lain -----------------

        toggleBusy() {
            if (this.isBusy == true) this.isBusy = false
            else this.isBusy = true
        },

        updateTotalItem(total) {
            this.perPage = this.size
            this.rows = total
            if (this.limit > this.rows) limit = this.rows
            this.totalViewText = "Menampilkan " + this.from + " - " + this.limit + " dari " + this.rows + " entri"
        },

    },

    created() {
        this.init()
    }
}
</script>
